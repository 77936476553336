import React, { useState } from 'react';
import EmailTask from './EmailTask';

const EmailTaskBig = ({ 
  subject, 
  from_email, 
  to_email, 
  prefix_text, 
  suffix_text, 
  onChange, 
  value, 
  imageUrl, 
  title, 
  assignmentText, 
  introduction, 
  introductionSuffix,
  readOnly,
  maxCharCount
}) => {
  const [emailValue, setEmailValue] = useState(value);

  const handleChange = (newValue) => {
    if (newValue.length <= maxCharCount) {
      setEmailValue(newValue);
      onChange(newValue);
    }
  };

  return (
    <div className="p-4 border-2 border-black shadow-lg my-4">
      <div className="flex flex-col md:flex-row">
        <div className="md:w-1/2 md:pr-2">
          <div className="mb-4">
            <p className="text-lg font-bold">{title}</p>
            <p className="italic" dangerouslySetInnerHTML={{ __html: introduction }}></p>
            {imageUrl && (
              <img src={imageUrl} alt="email-image" className="scaled mb-4" />
            )}
            <p className="italic" dangerouslySetInnerHTML={{ __html: introductionSuffix }}></p>
            <p className="text-lg font-bold mt-4">Opdracht</p>
            <div dangerouslySetInnerHTML={{ __html: assignmentText }} />
          </div>
        </div>
        <div className="md:w-1/2 md:pl-2">
          <EmailTask
            subject={subject}
            from_email={from_email}
            to_email={to_email}
            prefix_text={prefix_text}
            suffix_text={suffix_text}
            onChange={handleChange}
            value={emailValue}
            textRows={10}
            readOnly={readOnly}
            maxCharCount={maxCharCount}
          />
        </div>
      </div>
    </div>
  );
};

export default EmailTaskBig;