import { maxScores } from './constants';

export const validateReview = (review) => {
  if (!review) {
    console.log('Review is undefined');
    return false;
  }

  if (!('category' in review) || !(review.category in maxScores)) {
    console.log('Review has invalid or missing category');
    return false;
  }

  const requiredProperties = Object.keys(maxScores[review.category])
    .filter(key => key !== 'total')
    .flatMap(key => [`${key}`, `${key.replace('_score', '_tekst')}`]);

  const missingProperties = requiredProperties.filter(prop => !(prop in review));

  if (missingProperties.length > 0) {
    console.log(`Review is missing required properties for category ${review.category}: ${missingProperties.join(', ')}`);
    return false;
  }

  return true;
};


export const getScoreClass = (score, maxScore, isSelected = false) => {
    const percentage = (score / maxScore) * 100;
    if (percentage >= 75) return isSelected ? 'bg-green-200 border-2 border-blue-500 font-bold' : 'bg-green-200';
    if (percentage >= 50) return isSelected ? 'bg-amber-200 border-2 border-blue-500 font-bold' : 'bg-amber-200';
    return isSelected ? 'bg-red-200 border-2 border-blue-500 font-bold' : 'bg-red-200';
};