import React, { useState, useEffect } from 'react';
import axios from 'axios';
import useAuth from '../hooks/useAuth';

const AdminPage = () => {
  const [interactions, setInteractions] = useState([]);
  const [loading, setLoading] = useState(true);
  const { user } = useAuth();

  useEffect(() => {
    const fetchInteractions = async () => {
      try {
        const token = await user.getIdToken();
        const response = await axios.get('/api/admin-interactions', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setInteractions(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching interactions:', error);
        setLoading(false);
      }
    };

    fetchInteractions();
  }, [user]);

  if (loading) {
    return <div>Loading...</div>;
  }

  const formatOutput = (output) => {
    if (!output || !output.choices || output.choices.length === 0) {
      return 'No output available';
    }

    const choice = output.choices[0];
    const toolCall = choice.message.tool_calls[0];
    const functionArguments = JSON.parse(toolCall.function.arguments);

    return (
      <div>
        <p><strong>Function Name:</strong> {toolCall.function.name}</p>
        <p><strong>Arguments:</strong></p>
        <ul className="list-disc pl-5">
          {Object.entries(functionArguments).map(([key, value]) => (
            <li key={key}><strong>{key}:</strong> {value}</li>
          ))}
        </ul>
      </div>
    );
  };

  const calculateCost = (promptTokens, completionTokens) => {
    const promptTokenCost = (promptTokens / 1_000_000) * 5.00;
    const completionTokenCost = (completionTokens / 1_000_000) * 15.00;
    const totalCost = promptTokenCost + completionTokenCost;
    return totalCost.toFixed(4);
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6">Admin Page</h1>
      <h2 className="text-2xl font-semibold mb-4">AI Model Interactions</h2>
      {interactions.map((interaction, index) => (
        <div key={index} className="bg-white shadow-md rounded-lg p-6 mb-6">
          <h3 className="text-xl font-semibold mb-2">Interaction {index + 1}</h3>
          <p className="mb-1"><strong>Submission ID:</strong> {interaction.submissionId}</p>
          <p className="mb-1"><strong>Question ID:</strong> {interaction.questionId}</p>
          <p className="mb-3"><strong>Timestamp:</strong> {new Date(interaction.timestamp).toLocaleString()}</p>
          
          <div className="mb-4">
            <h4 className="text-lg font-semibold mb-2">Input:</h4>
            <div className="bg-gray-100 p-4 rounded-md">
              <p><strong>System Message:</strong></p>
              <pre className="whitespace-pre-wrap">{interaction.input.systemMessage.content}</pre>
              <p className="mt-2"><strong>User Message:</strong></p>
              <pre className="whitespace-pre-wrap">{interaction.input.userMessage.content}</pre>
            </div>
          </div>
          
          <div className="mb-4">
            <h4 className="text-lg font-semibold mb-2">Output:</h4>
            <div className="bg-gray-100 p-4 rounded-md">
              {formatOutput(interaction.output)}
            </div>
          </div>
          
          <div>
            <h4 className="text-lg font-semibold mb-2">Token Usage and Cost:</h4>
            <p><strong>Prompt Tokens:</strong> {interaction.tokenUsage.promptTokens}</p>
            <p><strong>Completion Tokens:</strong> {interaction.tokenUsage.completionTokens}</p>
            <p><strong>Total Cost:</strong> ${calculateCost(interaction.tokenUsage.promptTokens, interaction.tokenUsage.completionTokens)}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default AdminPage;