import React, { useState } from 'react';
import { maxScores } from '../constants';
import { validateReview } from '../utils';
import ChatBubble from './ChatBubble';

const ReviewChat = ({ review, userName }) => {
  const [userMessages, setUserMessages] = useState([]);
  const [currentMessage, setCurrentMessage] = useState('');
  const [isChatOpen, setIsChatOpen] = useState(false);

  const handleSendMessage = () => {
    if (currentMessage.trim()) {
      setUserMessages([...userMessages, currentMessage]);
      setCurrentMessage('');
    }
  };

  if (!validateReview(review)) {
    return null;
  }

  const criteriaItems = Object.entries(maxScores[review.category])
    .filter(([key]) => key !== 'total')
    .map(([key, maxScore]) => {
      const criteriaName = key.replace('_score', '');
      const score = parseInt(review[key]) || 0;
      const text = review[`${criteriaName}_tekst`] || '';
      return (
        <ChatBubble
          key={key}
          isUser={false}
          name={"AI docent"}
          content={text}
          score={score}
          maxScore={maxScore}
          subtitle={criteriaName.charAt(0).toUpperCase() + criteriaName.slice(1)}
        />
      );
    });

  const toggleChat = () => {
    setIsChatOpen(!isChatOpen);
  };

  const chatContent = (
    <div className="flex flex-col h-full">
      <div className="flex-1 overflow-y-auto">
        {criteriaItems}
        {userMessages.map((message, index) => (
          <ChatBubble
            key={index}
            isUser={true}
            name={userName}
            content={message}
          />
        ))}
      </div>
      <div className="flex items-center gap-2.5 mt-4">
        <input
          type="text"
          className="flex-1 p-2 border border-gray-300 rounded"
          value={currentMessage}
          onChange={(e) => setCurrentMessage(e.target.value)}
          placeholder="Stel een vraag..."
        />
        <button
          className="p-2 bg-blue-500 text-white rounded"
          onClick={handleSendMessage}
        >
          Send
        </button>
      </div>
    </div>
  );

  return (
    <>
      {/* Chat bubble for small screens */}
      <div 
        className="fixed bottom-24 right-4 w-16 h-16 bg-blue-500 rounded-full flex items-center justify-center cursor-pointer shadow-lg md:hidden"
        onClick={toggleChat}
      >
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z" />
        </svg>
        {criteriaItems.length > 0 && (
          <div className="absolute top-0 right-0 w-6 h-6 bg-red-500 text-white text-xs rounded-full flex items-center justify-center">
            {criteriaItems.length}
          </div>
        )}
      </div>

      {/* Full chat for larger screens */}
      <div className="hidden md:flex md:flex-col md:h-full md:p-4 md:border-l md:m-4 md:border-gray-50 md:bg-gray-50 md:drop-shadow-lg">
        {chatContent}
      </div>

      {/* Overlay chat for small screens when opened */}
      {isChatOpen && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full md:hidden" onClick={toggleChat}>
          <div className="relative top-20 mx-auto p-5 border w-11/12 shadow-lg rounded-md bg-white" onClick={(e) => e.stopPropagation()}>
            <div className="flex justify-between items-center pb-3">
              <h3 className="text-xl font-semibold">Review Chat</h3>
              <button onClick={toggleChat} className="text-black close-modal">&times;</button>
            </div>
            {chatContent}
          </div>
        </div>
      )}
    </>
  );
};

export default ReviewChat;
