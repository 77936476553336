import React from 'react';
import { maxScores, exams } from '../constants';
import { getScoreClass } from '../utils';

const ReviewTable = ({ reviewResults, setCurrentQuestionIndex, setIsReviewing, identifier }) => {
    const smallFont = true;
    if (!reviewResults?.answers) return null;

    const criteriaList = [
        'adequaatheid_score',
        'grammatica_score',
        'spelling_score',
        'samenhang_score',
        'woordgebruik_score'
    ];

    const renderSubscores = (review) => {
        return criteriaList.map((criteria) => {
            if (criteria in review) {
                const score = review[criteria];
                const maxScore = getMaxScore(review.category, criteria);
                return (
                    <td key={criteria} className={`relative group px-4 py-2 ${smallFont ? 'text-xs' : 'text-base'} ${score !== undefined ? getScoreClass(score, maxScore) : ''}`}>
                        {score !== undefined ? (
                            <>
                                <span>{score}/{maxScore}</span>
                            </>
                        ) : ''}
                    </td>
                );
            }
            return <td key={criteria} className={`px-4 py-2 ${smallFont ? 'text-xs' : 'text-base'}`}></td>;
        });
    };

    const getMaxScore = (category, criteria) => {
        const maxScore = maxScores[category][criteria];
        return maxScore || 0;
    };

    const totalScore = reviewResults.answers.reduce((sum, answer) => {
        if (answer.review) {
            return sum + answer.review.total_score;
        }
        return sum;
    }, 0);

    const maxTotalScore = reviewResults.answers.reduce((sum, answer) => {
        if (answer.review) {
            return sum + answer.review.max_score;
        }
        return sum;
    }, 0);

    const minScore = exams[identifier].minScore;
    const isPassed = totalScore >= minScore;
    const passFailMessage = isPassed ? 'GESLAAGD' : 'GEZAKT';
    const messageColor = isPassed ? 'text-green-600' : 'text-red-600';

    return (
        <div>
            <div className="mb-4 text-center">
                <h2 className={`text-2xl font-bold ${messageColor}`}>{passFailMessage}</h2>
                <p className="text-lg">
                    Totaalscore: <span className="font-bold">{totalScore}/{maxTotalScore}</span>
                </p>
                <p className="text-sm text-gray-600">
                    Minimaal vereiste score om te slagen: {minScore} punten
                </p>
            </div>
            
            <table className="min-w-full bg-white">
                <thead>
                    <tr>
                        <th className={`py-2 ${smallFont ? 'text-xs' : 'text-base'}`}>Vraag</th>
                        {criteriaList.map((criteria) => (
                            <th key={criteria} className={`py-2 ${smallFont ? 'text-xs' : 'text-base'}`}>{criteria.replace('_score', '')}</th>
                        ))}
                        <th className={`py-2 ${smallFont ? 'text-xs' : 'text-base'}`}>Score</th>
                    </tr>
                </thead>
                <tbody>
                    {reviewResults.answers.map((answer, index) => (
                        <tr key={answer.questionId}>
                            <td 
                                className={`px-4 py-2 cursor-pointer hover:bg-gray-100 ${smallFont ? 'text-xs' : 'text-base'}`}
                                onClick={() => {
                                    setCurrentQuestionIndex(index);
                                    setIsReviewing(false);
                                }}
                            >
                                Vraag {index + 1}
                            </td>
                            {answer.review ? renderSubscores(answer.review) : criteriaList.map((criteria) => (
                                <td key={criteria} className={`px-4 py-2 ${smallFont ? 'text-xs' : 'text-base'}`}></td>
                            ))}
                            <td className={`px-4 py-2 font-bold ${smallFont ? 'text-xs' : 'text-base'} ${answer.review ? getScoreClass(answer.review.total_score, answer.review.max_score) : ''}`}>
                                {answer.review ? `${answer.review.total_score}/${answer.review.max_score}` : ''}
                            </td>
                        </tr>
                    ))}
                </tbody>
                <tfoot>
                    <tr>
                        <td className={`px-4 py-2 font-bold ${smallFont ? 'text-xs' : 'text-base'}`}>Totaalscore</td>
                        <td className={`px-4 py-2 font-bold ${smallFont ? 'text-xs' : 'text-base'}`} colSpan={criteriaList.length}>
                        </td>
                        <td className={`px-4 py-2 font-bold ${smallFont ? 'text-xs' : 'text-base'}`}>
                            {totalScore}/{maxTotalScore}
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>
    );
};

export default ReviewTable;