import React from 'react';
import EmailTask from './tasks/EmailTask';
import SmallBoxTask from './tasks/SmallBoxTask';
import FormTask from './tasks/FormTask';
import EmailTaskBig from './tasks/EmailTaskBig';
import BigBoxTask from './tasks/BigBoxTask';
import { maxCharCounts } from '../constants';

const QuestionRenderer = ({ question, index, handleAnswerChange, answers, isSubmitted }) => {
  switch (question.type) {
    case 'email':
      return (
        <EmailTask
          key={index}
          title={question.title}
          introduction={question.introduction}
          subject={question.subject}
          from_email={question.from_email}
          to_email={question.to_email}
          prefix_text={question.prefix_text}
          suffix_text={question.suffix_text}
          imageUrl={question.image_url}
          onChange={(answer) => handleAnswerChange(question.identifier, answer)}
          value={answers[question.identifier] || ''}
          readOnly={isSubmitted}
          maxCharCount={maxCharCounts.email}
        />
      );
    case 'small_task_box':
      return (
        <SmallBoxTask
          key={index}
          title={question.title}
          subtitle={question.subtitle}
          introduction={question.introduction}
          subject={question.subject}
          prefix_text={question.prefix_text}
          suffix_text={question.suffix_text}
          onChange={(answer) => handleAnswerChange(question.identifier, answer)}
          value={answers[question.identifier] || ''}
          readOnly={isSubmitted}
          maxCharCount={maxCharCounts.small_task_box}
        />
      );
    case 'form_task':
      return (
        <FormTask
          key={index}
          title={question.title}
          introduction={question.introduction}
          formPrefixText={question.prefix_text}
          formSuffixText={question.suffix_text}
          formFields={question.form_fields}
          imageUrl={question.image_url}
          onChange={(answer) => handleAnswerChange(question.identifier, answer)}
          values={answers[question.identifier] || {}}
          readOnly={isSubmitted}
          maxCharCount={maxCharCounts.form_task}
        />
      );
    case 'email_task_big':
      return (
        <EmailTaskBig
          key={index}
          subject={question.subject}
          from_email={question.from_email}
          to_email={question.to_email}
          introduction={question.introduction}
          introductionSuffix={question.introduction_suffix}
          prefix_text={question.prefix_text}
          suffix_text={question.suffix_text}
          imageUrl={question.image_url}
          title={question.title}
          assignmentText={question.assignment_text}
          onChange={(answer) => handleAnswerChange(question.identifier, answer)}
          value={answers[question.identifier] || ''}
          readOnly={isSubmitted}
          maxCharCount={maxCharCounts.email_task_big}
        />
      );
    case 'big_box_task':
      return (
        <BigBoxTask
          key={index}
          title={question.title}
          introduction={question.introduction}
          introductionSuffix={question.introduction_suffix}
          assignmentText={question.assignment_text}
          imageUrl={question.image_url}
          subtitle={question.subtitle}
          onChange={(answer) => handleAnswerChange(question.identifier, answer)}
          value={answers[question.identifier] || ''}
          readOnly={isSubmitted}
          prefixText={question.prefix_text}
          suffixText={question.suffix_text}
          maxCharCount={maxCharCounts.big_box_task}
        />
      );
    default:
      return null;
  }
};

export default QuestionRenderer;