import React from 'react';

const SmallTaskBox = ({ title, introduction, subject, subtitle, prefix_text, suffix_text, onChange, value, readOnly, maxCharCount }) => {
  const handleChange = (event) => {
    if (event.target.value.length <= maxCharCount) {
      onChange(event.target.value);
    }
  };

  return (
    <div className="my-4 max-w-2xl">
      {title && <p className="text-lg font-bold">{title}</p>}
      {introduction && <p className="mb-2">{introduction}</p>}
    <div className="p-6 border-2 border-black shadow-lg">
      
      <div className="bg-white p-4">
        <p className="text-lg font-bold">{subject}</p>
        {subtitle && <p className="text-sm italic mt-2">{subtitle}</p>}
        <hr className="border-black my-2" />
        <p dangerouslySetInnerHTML={{ __html: prefix_text }}></p>
        <textarea
          className="w-full mt-4 p-2 border border-gray-400 rounded resize-none"
          rows="4"
          placeholder="Write your answer here..."
          onChange={handleChange}
          value={value}
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck="false"
          data-gramm="false"
          readOnly={readOnly}
        ></textarea>
        <p className="mt-4" dangerouslySetInnerHTML={{ __html: suffix_text }}></p>
      </div>
    </div>
    </div>
  );
};

export default SmallTaskBox;