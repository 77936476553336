import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import AuthPopup from '../components/AuthPopup';
import useAuth from '../hooks/useAuth';

const LandingPage = () => {
  const navigate = useNavigate();
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [isAuthPopupOpen, setIsAuthPopupOpen] = useState(false);
  const [pendingCheckout, setPendingCheckout] = useState(null);
  const { isAuthenticated, initiateCheckout, user } = useAuth();
  const handleLogin = () => {
    setIsAuthPopupOpen(true);
  };

  const handleAuthSuccess = () => {
    setIsAuthPopupOpen(false);
    if (selectedPlan && selectedPlan.title !== "Free") {
      setPendingCheckout(selectedPlan);
      setSelectedPlan(null);
    } else {
      navigate('/exams');
    }
  };

  const handleCheckout = async (planTitle) => {
    if (!isAuthenticated) {
      setSelectedPlan(planTitle);
      setIsAuthPopupOpen(true);
      return;
    }

    try {
      await initiateCheckout(planTitle);
    } catch (error) {
      console.error("Error during checkout", error);
    }
  };

  const handleSubscribeNow = (plan) => {
    handleCheckout(plan.title);
  };

  useEffect(() => {
    const initiatePendingCheckout = async () => {
      if (pendingCheckout && isAuthenticated) {
        try {
          console.log('initiating checkout', pendingCheckout, user);
          await initiateCheckout(pendingCheckout.planTitle);
          setPendingCheckout(null);
        } catch (error) {
          console.error("Error during pending checkout initiation", error);
        }
      }
    };
    initiatePendingCheckout();
  }, [pendingCheckout, isAuthenticated, initiateCheckout]);

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-100 to-indigo-200">
      <main className="container mx-auto px-4 py-16">
        <section className="mb-16 relative">
          <div className="container mx-auto px-4">
            <div className="flex flex-col md:flex-row items-center">
              <div className="w-full md:w-1/2 text-left mb-8 md:mb-0">
                <h1 className="text-5xl font-bold text-indigo-900 mb-4">
                  Master Your Dutch<br/>B1 Writing Exam
                </h1>
                <p className="text-xl text-indigo-700 mb-8">
                  Practice with official exam interfaces and get AI-powered feedback based on official review guidelines.
                </p>
                {isAuthenticated ? (
                  <Link
                    to="/exams"
                    className="bg-indigo-600 text-white px-8 py-3 rounded-full text-lg font-semibold hover:bg-indigo-700 transition duration-300"
                  >
                    Start Your Free Exam
                  </Link>
                ) : (
                  <button
                    onClick={handleLogin}
                    className="bg-indigo-600 text-white px-8 py-3 rounded-full text-lg font-semibold hover:bg-indigo-700 transition duration-300"
                  >
                    Start Your Free Exam
                  </button>
                )}
              </div>
              
              <div className="w-full md:w-1/2 aspect-video">
                <iframe 
                  src="https://player.vimeo.com/video/974223706?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&autoplay=1&loop=1&background=1" 
                  frameBorder="0" 
                  allow="autoplay; fullscreen; picture-in-picture; clipboard-write" 
                  className="w-full h-full rounded-lg shadow-lg"
                  title="B1 practice walkthrough"
                ></iframe>
              </div>
            </div>
          </div>
        </section>

        <section className="grid md:grid-cols-3 gap-8 mb-16">
          {[
            { title: 'Real Exams', icon: '📝', description: 'Experience the exam exactly as it will appear on the official test day.' },
            { title: 'AI Feedback', icon: '🤖', description: 'Receive detailed reviews and guidance based on official exam guidelines.' },
            { title: 'Track Progress', icon: '📊', description: 'Monitor your improvement over time with our comprehensive progress tracker.' },
          ].map((feature, index) => (
            <div key={index} className="bg-white p-6 rounded-lg shadow-md">
              <div className="text-4xl mb-4">{feature.icon}</div>
              <h3 className="text-xl font-semibold text-indigo-900 mb-2">{feature.title}</h3>
              <p className="text-indigo-700">{feature.description}</p>
            </div>
          ))}
        </section>

        <section className="mb-16">
          <h2 className="text-5xl font-bold text-indigo-900 mb-8 text-center">Choose Your Plan</h2>
          <div className="grid md:grid-cols-3 gap-8 text-wrap">
            {[
              { title: 'Free', amount: 0, features: ['1 Free Exam', 'AI-powered Feedback', 'Official Exam Interface', 'No Creditcard Required'], cta: 'Start Free Exam' },
              { title: 'Single Exam', price: '€9.99', amount: 999, features: ['1 Additional Exam', 'One-time Payment', 'No Subscription Fee', 'AI-powered Feedback'], cta: 'Buy Single Exam' },
              { title: 'Unlimited', price: '€24.95/month', amount: 2495, features: ['3 Real Exams', 'Unlimited Practice Exams', 'Progress Tracking', 'Targeted Practice Exercises', 'Unlimited Follow-up Questions', 'AI-powered Feedback'], cta: 'Subscribe Now', recommended: true },
            ].map((plan, index) => (
              <div key={index} className={`bg-white p-6 rounded-lg shadow-md flex flex-col transform transition duration-300 hover:scale-105 hover:shadow-xl ${plan.recommended ? 'border-4 border-indigo-600' : ''}`}>
                {plan.recommended && (
                  <div className="bg-indigo-600 text-white text-xs font-bold uppercase px-2 py-1 rounded-full absolute top-0 right-0 transform translate-x-2 -translate-y-2">
                    Best value
                  </div>
                )}
                <h3 className="text-2xl font-semibold text-indigo-900 mb-2">{plan.title}</h3>
                <p className="text-3xl font-bold text-indigo-600 mb-4">{plan.price}</p>
                <ul className="mb-6 flex-grow">
                  {plan.features.map((feature, i) => (
                    <li key={i} className="flex items-center mb-2 text-gray-700">
                      <svg className="w-5 h-5 mr-2 text-green-500" fill="currentColor" viewBox="0 0 20 20">
                        <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                      </svg>
                      {feature}
                    </li>
                  ))}
                </ul>
                <button
                  onClick={() => handleSubscribeNow(plan)}
                  className={`mt-auto ${plan.recommended ? 'bg-indigo-700' : 'bg-indigo-600'} text-white px-6 py-3 rounded-full font-semibold hover:bg-indigo-700 transform transition duration-300 hover:scale-105 hover:shadow-lg`}
                >
                  {plan.cta}
                </button>
              </div>
            ))}
          </div>
        </section>

        <section className="bg-white p-8 rounded-lg shadow-md mb-16">
          <h2 className="text-3xl font-bold text-indigo-900 mb-6">How It Works</h2>
          <ol className="space-y-4">
            {[
              'Sign up for a free account',
              'Choose an official B1 exam',
              'Complete the exam in a realistic interface',
              'Receive instant AI-powered feedback based on official exam guidelines',
              'Ask follow-up questions to improve your understanding',
              'Track your progress and improve your skills',
            ].map((step, index) => (
              <li key={index} className="flex items-center">
                <span className="bg-indigo-600 text-white rounded-full w-8 h-8 flex items-center justify-center mr-4">
                  {index + 1}
                </span>
                <span className="text-lg text-indigo-800">{step}</span>
              </li>
            ))}
          </ol>
        </section>

        <section className="text-center">
          <h2 className="text-3xl font-bold text-indigo-900 mb-6">Ready to Ace Your Dutch B1 Writing Exam?</h2>
          {isAuthenticated ? (
            <Link
              to="/exams"
              className="bg-indigo-600 text-white px-8 py-3 rounded-full text-lg font-semibold hover:bg-indigo-700 transition duration-300"
            >
              Start Practicing Now
            </Link>
          ) : (
            <button
              onClick={handleLogin}
              className="bg-indigo-600 text-white px-8 py-3 rounded-full text-lg font-semibold hover:bg-indigo-700 transition duration-300"
            >
              Log In to Begin
            </button>
          )}
        </section>
      </main>
      <AuthPopup
        isOpen={isAuthPopupOpen}
        onClose={() => setIsAuthPopupOpen(false)}
        onSuccess={handleAuthSuccess}
      />
    </div>
  );
};

export default LandingPage;
