import React, { useState } from 'react';

const BigBoxTask = ({ 
  title, 
  introduction, 
  introductionSuffix,
  imageUrl,
  assignmentText,
  subtitle,
  onChange, 
  value,
  readOnly,
  prefixText,
  suffixText,
  maxCharCount
}) => {
  const [taskValue, setTaskValue] = useState(value);

  const handleChange = (event) => {
    const newValue = event.target.value;
    if (newValue.length <= maxCharCount) {
      setTaskValue(newValue);
      onChange(newValue);
    }
  };

  return (
    <div className="p-4 border-2 border-black shadow-lg my-4">
      <div className="flex flex-col md:flex-row">
        <div className="w-full md:w-1/2 pr-2">
          <div className="mb-4">
            <p className="text-lg font-bold">{title}</p>
            <p className="italic" dangerouslySetInnerHTML={{ __html: introduction }}></p>
            {imageUrl && (
              <img src={imageUrl} alt="" className="scaled mb-4" />
            )}
            <p className="italic" dangerouslySetInnerHTML={{ __html: introductionSuffix }}></p>
            <p className="text-lg font-bold mt-4">Opdracht</p>
            <div dangerouslySetInnerHTML={{ __html: assignmentText }} />
          </div>
        </div>
        <div className="w-full md:w-1/2 pl-2">
          <p className="text-lg font-bold">{title}</p>
          {subtitle && <p className="text-sm italic mt-2">{subtitle}</p>}
          {prefixText && <p dangerouslySetInnerHTML={{ __html: prefixText }}></p>}
          <textarea
            className="w-full mt-4 p-2 border border-gray-400 rounded resize-none"
            rows={30}
            placeholder="Write your answer here..."
            onChange={handleChange}
            value={taskValue}
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
            data-gramm="false"
            readOnly={readOnly}
          ></textarea>
          {suffixText && <p dangerouslySetInnerHTML={{ __html: suffixText }}></p>}
        </div>
      </div>
    </div>
  );
};

export default BigBoxTask;