export const exams = {
    "b1_2021": { title: "B1 - Examen schrijven (2021)", minScore: 24, official: true },
    "b1_2022": { title: "B1 - Examen schrijven (2022)", minScore: 25, official: true },
    "b1_2023": { title: "B1 - Examen schrijven (2023)", minScore: 25, official: true },
    "b1_prac1": { title: "B1 - Oefenexamen schrijven #1", minScore: 25 },
    "b1_prac2": { title: "B1 - Oefenexamen schrijven #2", disabled: true },
    "b1_prac3": { title: "B1 - Oefenexamen schrijven #3", disabled: true },
    "b1_prac4": { title: "B1 - Oefenexamen schrijven #4", disabled: true },
    "b1_cat1": { title: "B1 - Oefenen zinstaak", disabled: false, isPractice: true },
    "b1_cat2": { title: "B1 - Oefenen formulier", disabled: false, isPractice: true },
    "b1_cat3": { title: "B1 - Oefenen aanvullen kort bericht", disabled: false, isPractice: true },
    "b1_cat4": { title: "B1 - Oefenen schrijven korte tekst", disabled: false, isPractice: true },
};

export const maxScores = {
    1: { adequaatheid_score: 1, grammatica_score: 1, total: 2 },
    2: { adequaatheid_score: 3, grammatica_score: 2, spelling_score: 1, woordgebruik_score: 1, total: 7 },
    3: { adequaatheid_score: 3, grammatica_score: 2, spelling_score: 1, samenhang_score: 1, woordgebruik_score: 1, total: 8 },
    4: { adequaatheid_score: 3, grammatica_score: 2, spelling_score: 1, samenhang_score: 1, woordgebruik_score: 1, total: 8 }
}

export const maxCharCounts = {
    email: 300,
    small_task_box: 300,
    form_task: 500,
    email_task_big: 3000,
    big_box_task: 3000
}
