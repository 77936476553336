import React from 'react';

const EmailTask = ({ title, introduction, subject, from_email, to_email, prefix_text, suffix_text, onChange, value, textRows = 3, readOnly, maxCharCount }) => {
  const handleChange = (event) => {
    if (event.target.value.length <= maxCharCount) {
      onChange(event.target.value);
    }
  };

  return (
    <div className="my-4 max-w-2xl">
      {title && <p className="text-lg font-bold">{title}</p>}
      {introduction && <p className="mb-2">{introduction}</p>}
      <div className="border-2 border-black shadow-lg">
        <div className="bg-blue-700 text-white font-bold py-2 px-4 flex justify-between items-center">
          <span>{subject}</span>
          <span className="flex space-x-2">
            <span>−</span>
            <span>☐</span>
            <span>✖</span>
          </span>
        </div>
        <div className="bg-blue-200 py-1 px-2 border-b border-gray-300">
          <ul className="list-none m-0 p-0 flex">
            <li className="inline-block text-sm mx-2">Bestand</li>
            <li className="inline-block text-sm mx-2">Bericht</li>
            <li className="inline-block text-sm mx-2">Invoegen</li>
            <li className="inline-block text-sm mx-2">Opties</li>
          </ul>
        </div>
        <div className="bg-white p-3">
          <div className="mb-2">
            <div className="flex items-center mb-1">
              <div className="w-32 text-center border border-gray-400 rounded-sm p-1 mr-2"><strong>Van</strong></div>
              <div className="flex-1 border-b border-gray-400 p-1">{from_email}</div>
            </div>
            <div className="flex items-center mb-1">
              <div className="w-32 text-center border border-gray-400 rounded-sm p-1 mr-2"><strong>Aan</strong></div>
              <div className="flex-1 border-b border-gray-400 p-1">{to_email}</div>
            </div>
            <div className="flex items-center mb-1">
              <div className="w-32 text-center border border-transparent p-1 mr-2"><strong>Onderwerp</strong></div>
              <div className="flex-1 border-b border-gray-400 p-1">{subject}</div>
            </div>
          </div>
          <hr className="border-black w-full" />
          <div className="bg-white p-2">
            <p dangerouslySetInnerHTML={{ __html: prefix_text }}></p>
            <textarea
              className="w-full mt-4 p-2 border border-gray-400 rounded resize-none"
              rows={textRows}
              placeholder="Write your answer here..."
              onChange={handleChange}
              value={value}
              autoCorrect="off"
              autoCapitalize="off"
              spellCheck="false"
              data-gramm="false"
              readOnly={readOnly}
            ></textarea>
            <p dangerouslySetInnerHTML={{ __html: suffix_text }}></p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailTask;
