import React, { useCallback } from 'react';
import { getScoreClass } from '../utils';

const NavigationButtons = ({
  examIdentifier,
  currentQuestionIndex,
  answers,
  questions,
  isReviewing,
  isSubmitted,
  handleSubmit,
  setCurrentQuestionIndex,
  setIsReviewing,
  reviewResults,
}) => {
  const isAnswerFilled = (answer) => {
    if (!answer || answer === null){
      return false;
    }
    if (typeof answer === 'object') {
      return Object.values(answer).every((val) => val !== '');
    }
    return answer !== '';
  }; 

  const getButtonClass = useCallback((index) => {
    const answerKey = `${examIdentifier}_${index + 1}`;
    const isFilled = isAnswerFilled(answers[answerKey]);
    if (reviewResults && reviewResults.answers) {
      const review = reviewResults.answers.find(a => a.questionId === answerKey)?.review;
      if (review) {
        return getScoreClass(review.total_score, review.max_score, currentQuestionIndex === index && !isReviewing);
      }
    }

    if (currentQuestionIndex === index && !isReviewing) {
      return 'bg-blue-500 text-white';
    } else if (isFilled && !isReviewing) {
      return 'bg-blue-200';
    }
    return 'bg-gray-200';
  }, [answers, reviewResults, currentQuestionIndex, isReviewing]);

  return (
    <div className="w-full bg-white p-4">
      <div className="flex justify-between items-center max-w-4xl mx-auto">
        <button
          className="bg-blue-500 text-white px-4 py-2 rounded disabled:opacity-50"
          onClick={() => setCurrentQuestionIndex((prev) => Math.max(prev - 1, 0))}
          disabled={currentQuestionIndex === 0}
        >
          Previous
        </button>
        <div className="flex justify-center space-x-2 flex-grow">
          {questions.map((_, index) => (
            <button
              key={index}
              className={`px-3 py-1 rounded-full ${getButtonClass(index)} ${
                currentQuestionIndex !== index ? 'hidden sm:inline-block' : ''
              }`}
              onClick={() => {
                setCurrentQuestionIndex(index);
                setIsReviewing(false);
              }}
            >
              {index + 1}
            </button>
          ))}
        </div>
        <div className="flex space-x-2">
          {currentQuestionIndex === questions.length - 1 && !isSubmitted ? (
            <button
              className="bg-green-500 text-white px-4 py-2 rounded"
              onClick={handleSubmit}
            >
              Submit
            </button>
          ) : (
            <button
              className="bg-blue-500 text-white px-4 py-2 rounded disabled:opacity-50"
              onClick={() => setCurrentQuestionIndex((prev) => Math.min(prev + 1, questions.length - 1))}
              disabled={currentQuestionIndex === questions.length - 1}
            >
              Next
            </button>
          )}
          {isSubmitted && (
            <button
              className={`bg-blue-500 text-white px-4 py-2 rounded ${isReviewing ? 'bg-blue-700' : ''}`}
              onClick={() => setIsReviewing(true)}
            >
              Review
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default NavigationButtons;