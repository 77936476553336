import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import QuestionRenderer from '../components/QuestionRenderer';
import PracticeNavigation from '../components/PracticeNavigation';
import ReviewChat from '../components/ReviewChat';
import useAuth from '../hooks/useAuth';
import useExam from '../hooks/useExam';
import { exams } from '../constants';

const Practice = () => {
  const { user, isAuthenticated } = useAuth();
  const { identifier } = useParams();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const userName = user?.displayName || user?.email || "Gebruiker";
  const title = exams[identifier]?.title || "Practice";
  const location = useLocation();

  const {
    questions,
    answers,
    originalAnswers,
    reviewResults,
    loading,
    handleAnswerChange,
    handleSubmitQuestion,
    initExam,
    fetchExamQuestions,
  } = useExam(user, identifier, location.state?.examResult, true);

  useEffect(() => {
    if (isAuthenticated) {
      initExam();
      fetchExamQuestions();
    }
  }, [isAuthenticated, initExam, fetchExamQuestions]);

  const currentQuestion = questions && questions.length > 0 ? questions[currentQuestionIndex] : null;

  return (
    <div className="flex flex-col h-full max-w-6xl mx-auto overflow-hidden">
      <h1 className="text-lg font-bold text-center">{title}</h1>
      <div className="flex-1 overflow-y-auto pb-24">
        {loading ? (
          <div className="flex justify-center items-center h-full">
            <div className="loader">{typeof loading === 'string' ? loading : 'Bezig met nakijken...'}</div>
          </div>
        ) : currentQuestion ? (
          <div className="flex w-full">
            <div className="w-full mx-auto">
              <QuestionRenderer
                question={currentQuestion}
                index={currentQuestionIndex}
                handleAnswerChange={handleAnswerChange}
                answers={answers}
              />
            </div>
            {reviewResults && reviewResults.answers && reviewResults.answers[currentQuestionIndex] && (
              <div className="lg:w-1/3">
                <ReviewChat review={reviewResults.answers[currentQuestionIndex].review} userName={userName} />
              </div>
            )}
          </div>
        ) : (
          <div className="flex justify-center items-center h-full">
            <p>No questions available.</p>
          </div>
        )}
      </div>
      {questions && questions.length > 0 && (
        <div className="fixed bottom-0 left-0 right-0 bg-white">
          <PracticeNavigation
            examIdentifier={identifier}
            currentQuestionIndex={currentQuestionIndex}
            answers={answers}
            originalAnswers={originalAnswers}
            questions={questions}
            handleSubmitQuestion={handleSubmitQuestion}
            setCurrentQuestionIndex={setCurrentQuestionIndex}
            reviewResults={reviewResults}
          />
        </div>
      )}
    </div>
  );
};

export default Practice;