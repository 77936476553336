import { useState, useEffect, useCallback, useRef } from 'react';
import { debounce } from 'lodash';
import { initializeExam, fetchQuestions, submitExam, fetchReviewStatuses, fetchReviewResults, reviewSingleQuestion, checkReviewStatus } from '../api';
import axios from 'axios';

const useExam = (user, identifier, initialReviewData, isPractice = false) => {
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState({});
  const [originalAnswers, setOriginalAnswers] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [reviewResults, setReviewResults] = useState(null);
  const [isReviewing, setIsReviewing] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submissionId, setSubmissionId] = useState(null);
  const [loading, setLoading] = useState(false);
  const debouncedUpdateAnswerRef = useRef();

  useEffect(() => {
    debouncedUpdateAnswerRef.current = debounce(async (questionId, answer) => {
      if (!submissionId) {
        console.log('No submissionId found, skipping answer update');
        return;
      }

      try {
        const token = await user.getIdToken();
        console.log('Updating answer in database...');
        await axios.post(
          '/api/update-answer',
          { submissionId, questionId, answer },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log('Answer updated successfully');
      } catch (error) {
        console.error('Error updating answer:', error);
      }
    }, 2000);
  }, [user, submissionId]);

  const handleAnswerChange = useCallback((questionId, answer) => {
    if (isSubmitted && !isPractice) {
      console.log('Exam is submitted, cannot change answers');
      return;
    }

    console.log(`Updating answer for question ${questionId}:`, answer);
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionId]: answer,
    }));

    debouncedUpdateAnswerRef.current(questionId, answer);
  }, [isSubmitted, isPractice]);

  useEffect(() => {
    if (initialReviewData) {
      console.log('Setting review data...');
      setReviewResults(initialReviewData);
      setIsSubmitted(initialReviewData.submitted);
      setIsReviewing(initialReviewData.submitted);
      setSubmissionId(initialReviewData.submissionId);

      const reviewAnswers = initialReviewData.answers.reduce((acc, answer) => {
        if (!acc.some(a => a.questionId === answer.questionId)) {
          acc.push(answer);
        }
        return acc;
      }, []);
      const initialAnswers = reviewAnswers.reduce((acc, answer) => {
        acc[answer.questionId] = answer.answer;
        return acc;
      }, {});
      setAnswers(initialAnswers);
      setOriginalAnswers(initialAnswers);
    }
  }, [initialReviewData]);

  const initExam = useCallback(async () => {
    if (!user || !identifier) return;

    console.log('Initializing exam...');
    if (!initialReviewData) {
      console.log('No review data found, initializing new exam...');
      try {
        const token = await user.getIdToken();
        const submission = await initializeExam(identifier, token);
        console.log('Exam initialized:', submission);
        setSubmissionId(submission._id);
        setAnswers(submission.answers.reduce((acc, answer) => {
          acc[answer.questionId] = answer.answer;
          return acc;
        }, {}));
        setIsSubmitted(submission.submitted);
        setReviewResults(submission.answers);
      } catch (error) {
        console.error('Error initializing exam:', error);
        throw error;
      }
    }
  }, [user, identifier, initialReviewData]);

  const fetchExamQuestions = useCallback(async () => {
    console.log('Fetching questions...');
    try {
      const fetchedQuestions = await fetchQuestions(identifier);
      console.log('Questions fetched:', fetchedQuestions);
      setQuestions(fetchedQuestions);
    } catch (error) {
      console.error('Error fetching questions:', error);
    }
  }, [identifier]);

  const handleSubmit = useCallback(async () => {
    console.log('Submitting exam...');
    if (!user) {
      throw new Error('User not authenticated');
    }

    if (isSubmitting) {
      console.log('Submission already in progress, skipping');
      return;
    }
    setIsSubmitting(true);
    setLoading(true);

    try {
      const token = await user.getIdToken();

      const allAnswers = questions.reduce((acc, question) => {
        acc[question.identifier] = answers[question.identifier] || '';
        return acc;
      }, {});
      console.log('Submitting answers:', allAnswers);

      await submitExam(allAnswers, identifier, submissionId, token);
      console.log('Answers submitted successfully');

      console.log('Starting question review process...');
      await reviewQuestionsSequentially(token);
    } catch (error) {
      console.error('Error submitting answers:', error);
      setLoading(false);
    } finally {
      setIsSubmitting(false);
    }
  }, [user, questions, answers, identifier, isSubmitting, submissionId]);

  const reviewQuestionsSequentially = async (token) => {
    console.log('Reviewing questions sequentially...');
    let reviewedCount = 0;

    const reviewPromises = questions.map(async (question) => {
      console.log(`Submitting review for question ${question.identifier} (${reviewedCount + 1}/${questions.length})`);
      await reviewSingleQuestion(submissionId, question.identifier, token);
      reviewedCount++;
      setLoading(`Bezig met nakijken ${reviewedCount}/${questions.length}...`);
    });

    await Promise.all(reviewPromises);

    console.log('All questions submitted for review, fetching review statuses...');

    const pollReviewStatuses = async () => {
      try {
        const statuses = await fetchReviewStatuses(submissionId, token);
        const completedCount = statuses.filter(status => status.review_status === 'COMPLETED').length;
        setLoading(`${completedCount}/${questions.length} vragen nagekeken...`);

        if (completedCount < questions.length) {
          setTimeout(pollReviewStatuses, 1000);
        } else {
          console.log('All questions reviewed, fetching final results...');
          const results = await fetchReviewResults(submissionId, token);
          setReviewResults(results);
          setIsSubmitted(true);
          setIsReviewing(true);
          setLoading(false);
        }
      } catch (error) {
        console.error('Error fetching review statuses:', error);
      }
    };

    pollReviewStatuses();
  };

  const handleSubmitQuestion = useCallback(async (questionId, retry = false) => {
    if (!user) {
      throw new Error('User not authenticated');
    }

    setLoading(true);

    try {
      const token = await user.getIdToken();
      const answer = answers[questionId];

      await submitExam({ [questionId]: answer }, identifier, submissionId, token);
      console.log('Answer submitted successfully');

      const reviewResponse = await reviewSingleQuestion(submissionId, questionId, token, retry);
      
      if (reviewResponse.status === 'STARTED') {
        await pollReviewStatus(submissionId, questionId, token);
      }

      const results = await fetchReviewResults(submissionId, token);
      setReviewResults(results);
      setIsSubmitted(true);
      setOriginalAnswers((prev) => ({ ...prev, [questionId]: answer }));
    } catch (error) {
      console.error('Error submitting answer:', error);
    } finally {
      setLoading(false);
    }
  }, [user, answers, identifier, submissionId]);

  const pollReviewStatus = async (submissionId, questionId, token) => {
    const maxAttempts = 30;
    const delayMs = 2000;
    let attempts = 0;

    while (attempts < maxAttempts) {
      try {
        const status = await checkReviewStatus(submissionId, questionId, token);
        console.log(`Review status for question ${questionId}:`, status);
        if (status.review_status === 'COMPLETED') {
          return;
        } else if (status.review_status === 'ERROR') {
          throw new Error('Review failed');
        }
      } catch (error) {
        console.error(`Error checking review status for question ${questionId}:`, error);
      }

      await new Promise(resolve => setTimeout(resolve, delayMs));
      attempts++;
    }

    throw new Error('Review timed out');
  };

  return {
    questions,
    answers,
    originalAnswers,
    isSubmitted,
    reviewResults,
    isReviewing,
    loading,
    handleAnswerChange,
    handleSubmit,
    handleSubmitQuestion,
    initExam,
    fetchExamQuestions,
    setIsReviewing,
  };
};

export default useExam;