import React from 'react';
import { Link } from 'react-router-dom';

const Cancel = () => {
  return (
    <div className="min-h-screen flex items-center justify-center bg-red-100">
      <div className="bg-white p-8 rounded-lg shadow-md text-center">
        <h1 className="text-3xl font-bold text-red-600 mb-4">Payment Unsuccessful</h1>
        <p className="text-lg text-gray-700 mb-8">Your payment was not successful or was canceled. Please try again.</p>
        <Link to="/" className="bg-red-600 text-white px-6 py-3 rounded-full font-semibold hover:bg-red-700 transition duration-300">
          Go Back to Home
        </Link>
      </div>
    </div>
  );
};

export default Cancel;