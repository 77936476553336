import { useState, useEffect } from 'react';
import { auth } from '../firebase';
import { onAuthStateChanged, signInWithPopup, signOut, GoogleAuthProvider, createUserWithEmailAndPassword, signInWithEmailAndPassword, updateProfile, sendPasswordResetEmail } from "firebase/auth";
import axios from 'axios';
import { loadStripe } from '@stripe/stripe-js';

const useAuth = () => {
  const [user, setUser] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [authError, setAuthError] = useState(null);
  const [userPlan, setUserPlan] = useState(null); // New state for user plan
  const provider = new GoogleAuthProvider();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
        setIsAuthenticated(true);
        checkUserPlan(user); // Pass the user object to checkUserPlan
      } else {
        setUser(null);
        setIsAuthenticated(false);
        setUserPlan(null); // Reset user plan on logout
      }
    });

    return () => unsubscribe();
  }, []);

  const handleGoogleLogin = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      setUser(result.user);
      setIsAuthenticated(true);
      checkUserPlan(result.user); // Pass the user object to checkUserPlan
      return result.user;
    } catch (error) {
      console.error("Error during Google login", error);
      setAuthError(error.message);
      throw error;
    }
  };

  const handleEmailSignUp = async (email, password, username) => {
    try {
      const result = await createUserWithEmailAndPassword(auth, email, password);
      await updateProfile(result.user, { displayName: username });
      setUser(result.user);
      setIsAuthenticated(true);
      checkUserPlan(result.user); // Pass the user object to checkUserPlan
      return result.user;
    } catch (error) {
      console.error("Error during email sign-up", error);
      setAuthError(error.message);
      throw error;
    }
  };

  const handleEmailLogin = async (email, password) => {
    try {
      const result = await signInWithEmailAndPassword(auth, email, password);
      setUser(result.user);
      setIsAuthenticated(true);
      checkUserPlan(result.user); // Pass the user object to checkUserPlan
      return result.user;
    } catch (error) {
      console.error("Error during email login", error);
      setAuthError(error.message);
      throw error;
    }
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      setUser(null);
      setIsAuthenticated(false);
      setUserPlan(null); // Reset user plan on logout
    } catch (error) {
      console.error("Error during logout", error);
      setAuthError(error.message);
      throw error;
    }
  };

  const handleForgotPassword = async (email) => {
    try {
      await sendPasswordResetEmail(auth, email);
    } catch (error) {
      console.error("Error sending password reset email", error);
      setAuthError(error.message);
      throw error;
    }
  };

  const initiateCheckout = async (planTitle) => {
    if (!user) {
      throw new Error('User not authenticated');
    }

    try {
      const token = await user.getIdToken();
      const { data } = await axios.post('/api/create-checkout-session', {
        planTitle,
        userId: user.uid,
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const stripe = await loadStripe('pk_test_51PYUMAIRvHeiMfbQlAfFM9Q43c74zX2VJmHo6cG8z7JsCLVACIZAhInoieckPLvIjd3upeGhPoYPfNlgIRsrNPv600uKz5tzXR');
      await stripe.redirectToCheckout({ sessionId: data.id });
    } catch (error) {
      console.error("Error during checkout initiation", error);
      setAuthError(error.message);
      throw error;
    }
  };

  const checkUserPlan = async (user) => {
    try {
      // Force token refresh to get the latest claims
      await user.getIdToken(true);
      const claims = (await user.getIdTokenResult()).claims;
      if (claims.plan) {
        setUserPlan({
          plan: claims.plan,
          planStatus: claims.planStatus,
          planAmount: claims.planAmount,
          planNumberExams: claims.planNumberExams,
          planCurrency: claims.planCurrency,
          planStartDate: claims.planStartDate,
        });
      } else {
        setUserPlan(null);
      }
    } catch (error) {
      console.error("Error checking user plan", error);
      setAuthError(error.message);
    }
  };

  return {
    user,
    isAuthenticated,
    authError,
    userPlan, // Return user plan
    handleGoogleLogin,
    handleEmailSignUp,
    handleEmailLogin,
    handleLogout,
    handleForgotPassword,
    initiateCheckout,
    checkUserPlan, // Return checkUserPlan function
  };
};

export default useAuth;
