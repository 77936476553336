import axios from 'axios';

const reviewSingleQuestion = async (submissionId, questionId, token, retry = false) => {
  try {
    console.log(`Starting review for question ${questionId}${retry ? ' (retry)' : ''}`);
    const response = await axios.post(
      '/api/review-question',
      { submissionId, questionId, retry },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(`Error reviewing question ${questionId}:`, error);
    throw error;
  }
};

const checkReviewStatus = async (submissionId, questionId, token) => {
  try {
    const response = await axios.get(
      `/api/review-status?submissionId=${submissionId}&questionId=${questionId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(`Error checking review status for question ${questionId}:`, error);
    throw error;
  }
};

const initializeExam = async (identifier, token) => {
  const response = await axios.post(
    '/api/init-exam',
    { exam_identifier: identifier },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

const fetchQuestions = async (identifier) => {
  const response = await axios.get(`/api/question?identifier=${identifier}`);
  return response.data;
};

const submitExam = async (answers, identifier, submissionId, token) => {
  await axios.post(
    '/api/submit',
    { answers, exam_identifier: identifier, submissionId },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const fetchReviewStatuses = async (submissionId, token) => {
  const response = await axios.get(`/api/status?submissionId=${submissionId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data.statuses;
};

const fetchReviewResults = async (submissionId, token) => {
  const response = await axios.get(`/api/user-results?submissionId=${submissionId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data[0];
};

export {
  reviewSingleQuestion,
  checkReviewStatus,
  initializeExam,
  fetchQuestions,
  submitExam,
  fetchReviewStatuses,
  fetchReviewResults
};