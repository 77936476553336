import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { LandingPage, Exam, Results, ExamOverview, Success, Cancel, PrivacyPolicy } from './pages';
import { Navbar } from './components/Navbar';
import useAuth from './hooks/useAuth';
import axios from 'axios';
import './firebase'; // This ensures Firebase is initialized
import AdminPage from './pages/AdminPage'; // Import the new AdminPage component
import Practice from './pages/Practice';

const App = () => {
  const { user, handleGoogleLogin, handleEmailSignUp, handleEmailLogin, handleLogout, authError, isAuthenticated } = useAuth();
  const [loading, setLoading] = useState(true);
  const [userResults, setUserResults] = useState([]);

  useEffect(() => {
    const fetchUserResults = async () => {
      if (user) {
        console.log("Fetching user results")
        try {
          const token = await user.getIdToken();
          const response = await axios.get('/api/user-results', {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          setUserResults(response.data);
          setLoading(false);
        } catch (error) {
          console.error('Error fetching user results:', error);
          setLoading(false);
        }
      }
    };

    fetchUserResults();
  }, [user, isAuthenticated]);



  return (
    <div>
      <Router>
        <div>
          <Navbar user={user} onLogin={handleGoogleLogin} onEmailSignUp={handleEmailSignUp} onEmailLogin={handleEmailLogin} onLogout={handleLogout} authError={authError} />
          <div className="flex justify-center">
            <div className="w-full max-w-5xl">
              <Routes>
                <Route path="/exams" element={<ExamOverview isPractice={false} />} />
                <Route path="/practice" element={<ExamOverview isPractice={true} />} />
                <Route path="/exam/:identifier" element={<Exam />} />
                <Route path="/practice/:identifier" element={<Practice />} />
                <Route path="/results" element={<Results userResults={userResults} setUserResults={setUserResults} loading={loading} setLoading={setLoading} />} />
                <Route path="/success" element={<Success />} />
                <Route path="/cancel" element={<Cancel />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/admin" element={<AdminPage />} />
                <Route path="/" element={<LandingPage />} />
              </Routes>
            </div>
          </div>
        </div>
      </Router>
    </div>
  );
};

export default App;