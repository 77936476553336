import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { exams } from '../constants';
import useAuth from '../hooks/useAuth';

const ExamOverview = ({ isPractice = false }) => {
  const { userPlan } = useAuth();
  const navigate = useNavigate();
  const [showPopup, setShowPopup] = useState(false);

  const handleExamClick = (identifier) => {
    if (identifier !== 'b1_2021' && (!userPlan || userPlan.planNumberExams <= 0)) {
      setShowPopup(true);
    } else {
      if (!isPractice) {
        navigate(`/exam/${identifier}`);
      } else {
        navigate(`/practice/${identifier}`);
      }
    }
  };

  const officialExams = Object.entries(exams).filter(([_, exam]) => exam.official);
  const practiceExams = Object.entries(exams).filter(([_, exam]) => !exam.official && !exam.isPractice);
  const practiceExercises = Object.entries(exams).filter(([_, exam]) => exam.isPractice);

  return (
    <div className="flex flex-col items-center">
    {isPractice && (
      <>
      <h2 className="text-xl font-semibold mb-2">Oefeningen per categorie</h2>
      <ul className="w-full max-w-md list-none mb-6">
        {practiceExercises.map(([identifier, exam]) => (
          <li key={identifier} className="mb-4">
            {exam.disabled ? (
              <div className="block bg-gray-500 text-white py-2 px-4 rounded text-center cursor-not-allowed">
                {exam.title}
              </div>
            ) : (
              <div
                onClick={() => handleExamClick(identifier)}
                className="block bg-yellow-500 text-white py-2 px-4 rounded text-center cursor-pointer"
              >
                {exam.title}
              </div>
            )}
          </li>
        ))}
      </ul>
      </>
    )}
    {!isPractice && (
      <>
      <h2 className="text-xl font-semibold mb-2">Staatsexamen Nt2</h2>
      <ul className="w-full max-w-md list-none mb-6">
        {officialExams.map(([identifier, exam]) => (
          <li key={identifier} className="mb-4">
            {exam.disabled ? (
              <div className="block bg-gray-500 text-white py-2 px-4 rounded text-center cursor-not-allowed">
                {exam.title}
              </div>
            ) : (
              <div
                onClick={() => handleExamClick(identifier)}
                className="block bg-blue-500 text-white py-2 px-4 rounded text-center cursor-pointer"
              >
                {exam.title}
              </div>
            )}
          </li>
        ))}
      </ul>

      <h2 className="text-xl font-semibold mb-2">Oefenexamens</h2>
      <ul className="w-full max-w-md list-none">
        {practiceExams.map(([identifier, exam]) => (
          <li key={identifier} className="mb-4">
            {exam.disabled ? (
              <div className="block bg-gray-500 text-white py-2 px-4 rounded text-center cursor-not-allowed">
                {exam.title}
              </div>
            ) : (
              <div
                onClick={() => handleExamClick(identifier)}
                className="block bg-green-500 text-white py-2 px-4 rounded text-center cursor-pointer"
              >
                {exam.title}
              </div>
            )}
          </li>
        ))}
      </ul>
      </>
    )}

      {showPopup && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h2 className="text-xl font-bold mb-4">Not Enough Credits</h2>
            <p className="mb-4">You do not have enough credits to start this exam. Please purchase more credits{<Link to="/" className="text-blue-500 hover:text-blue-700"> here</Link>} or choose the 2021 exam which is free.</p>
            <button
              onClick={() => setShowPopup(false)}
              className="bg-blue-500 text-white px-4 py-2 rounded"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ExamOverview;