import React from 'react';
import PropTypes from 'prop-types';
import { getScoreClass } from '../utils';

const ChatBubble = ({ isUser, name, content, score, maxScore, subtitle }) => {

  return (
    <div className={`flex items-start gap-2.5 mb-4 ${isUser ? 'justify-end' : ''}`}>
      <div className={`flex flex-col w-full max-w-[320px] p-4 border-gray-200 ${isUser ? 'bg-blue-100' : 'bg-gray-100'} ${isUser ? 'rounded-e-xl rounded-es-xl' : 'rounded-s-xl rounded-tr-none'}`}>
        <div className="flex items-center justify-between">
          <span className="text-sm font-semibold text-gray-900">{name}</span>
          {score !== undefined && maxScore !== undefined && (
            <span className={`text-sm font-semibold text-gray-900 ${getScoreClass(score, maxScore)}`}>{`${score}/${maxScore}`}</span>
          )}
        </div>
        <p className="text-sm font-normal py-2.5 text-gray-900">{content}</p>
        {subtitle && (
          <span className="text-sm font-normal text-gray-500">{subtitle}</span>
        )}
      </div>
    </div>
  );
};

ChatBubble.propTypes = {
  isUser: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  score: PropTypes.number,
  maxScore: PropTypes.number,
  subtitle: PropTypes.string,
};

export default ChatBubble;
