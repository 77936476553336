import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6">Privacy Policy</h1>
      <p className="mb-4"><strong>Effective Date:</strong> 7 July 2024</p>

      <h2 className="text-2xl font-semibold mt-8 mb-4">1. Introduction</h2>
      <p className="mb-4">Welcome to Dutch Exams. We are committed to protecting your privacy and ensuring that your personal information is handled in a safe and responsible manner. This Privacy Policy outlines how we collect, use, and protect your personal data.</p>

      <h2 className="text-2xl font-semibold mt-8 mb-4">2. Business Information</h2>
      <p className="mb-2"><strong>Name:</strong> Dutch Exams</p>
      <p className="mb-4"><strong>Website:</strong> www.dutchexams.nl</p>

      <h2 className="text-2xl font-semibold mt-8 mb-4">3. Contact Information</h2>
      <p className="mb-2">For any privacy-related inquiries, you can reach us at:</p>
      <p className="mb-4"><strong>Email:</strong> privacy@dutchexams.nl</p>

      <h2 className="text-2xl font-semibold mt-8 mb-4">4. Data Collection</h2>
      <p className="mb-2">We collect the following types of personal data from our users:</p>
      <ul className="list-disc pl-6 mb-4">
        <li>Full name</li>
        <li>Username</li>
        <li>Email address</li>
        <li>Payment information</li>
      </ul>

      <h2 className="text-2xl font-semibold mt-8 mb-4">5. Lawful Basis for Processing</h2>
      <p className="mb-2">We process your personal data based on the following legal grounds:</p>
      <ul className="list-disc pl-6 mb-4">
        <li>Your consent</li>
        <li>Performance of a contract</li>
        <li>Compliance with legal obligations</li>
        <li>Legitimate interests</li>
      </ul>

      <h2 className="text-2xl font-semibold mt-8 mb-4">6. Data Usage</h2>
      <p className="mb-2">The collected data is used for the following purposes:</p>
      <ul className="list-disc pl-6 mb-4">
        <li>To improve user experience</li>
        <li>For marketing purposes</li>
      </ul>

      <h2 className="text-2xl font-semibold mt-8 mb-4">7. Data Sharing</h2>
      <p className="mb-4">We share data with third parties only through Google Analytics to analyze user behavior on our website.</p>

      <h2 className="text-2xl font-semibold mt-8 mb-4">8. Data Retention</h2>
      <p className="mb-4">We retain personal data only for as long as necessary to fulfill the purposes we collected it for, including for the purposes of satisfying any legal, accounting, or reporting requirements.</p>

      <h2 className="text-2xl font-semibold mt-8 mb-4">9. User Rights</h2>
      <p className="mb-2">Under the GDPR, you have the following rights:</p>
      <ul className="list-disc pl-6 mb-4">
        <li>Access: You can request access to your personal data.</li>
        <li>Correction: You can request correction of any inaccurate data.</li>
        <li>Deletion: You can request deletion of your personal data.</li>
        <li>Restriction: You can request restriction of processing of your personal data.</li>
        <li>Portability: You can request the transfer of your personal data to another party.</li>
        <li>Objection: You can object to the processing of your personal data in certain circumstances.</li>
      </ul>
      <p className="mb-4">To exercise any of these rights, please contact us at: privacy@dutchexams.nl</p>

      <h2 className="text-2xl font-semibold mt-8 mb-4">10. Security Measures</h2>
      <p className="mb-4">We are committed to ensuring the security of your personal data. To safeguard your information, we employ robust security measures including the use of third-party authentication services. By not storing personal data on our own servers, we significantly reduce the risk of data breaches and unauthorized access.</p>

      <h2 className="text-2xl font-semibold mt-8 mb-4">11. Cookies and Tracking</h2>
      <p className="mb-4">We use Google Analytics for tracking user behavior. In the future, we may use Datadog to analyze usage. No other cookies or tracking technologies are currently used.</p>

      <h2 className="text-2xl font-semibold mt-8 mb-4">12. International Data Transfers</h2>
      <p className="mb-4">If we transfer your personal data outside the European Economic Area (EEA), we ensure it is protected by appropriate safeguards, such as standard contractual clauses approved by the European Commission.</p>

      <h2 className="text-2xl font-semibold mt-8 mb-4">13. Automated Decision-Making and Profiling</h2>
      <p className="mb-4">We do not engage in automated decision-making or profiling that produces legal effects or similarly significantly affects you.</p>

      <h2 className="text-2xl font-semibold mt-8 mb-4">14. Data Protection Officer (DPO)</h2>
      <p className="mb-4">We have not appointed a Data Protection Officer, but you can contact us at privacy@dutchexams.nl for any data protection inquiries.</p>

      <h2 className="text-2xl font-semibold mt-8 mb-4">15. Legal Compliance</h2>
      <p className="mb-4">We are compliant with the General Data Protection Regulation (GDPR).</p>

      <h2 className="text-2xl font-semibold mt-8 mb-4">16. Changes to this Privacy Policy</h2>
      <p className="mb-4">We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>

      <h2 className="text-2xl font-semibold mt-8 mb-4">17. Contact Us</h2>
      <p className="mb-2">If you have any questions or concerns about this Privacy Policy, please contact us at:</p>
      <p className="mb-4"><strong>Email:</strong> privacy@dutchexams.nl</p>
    </div>
  );
};

export default PrivacyPolicy;