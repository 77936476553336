import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import ReviewTable from '../components/ReviewTable';
import QuestionRenderer from '../components/QuestionRenderer';
import NavigationButtons from '../components/NavigationButtons';
import ReviewChat from '../components/ReviewChat';
import useAuth from '../hooks/useAuth';
import useExam from '../hooks/useExam';
import { exams } from '../constants';

const Exam = () => {
  const { user, isAuthenticated } = useAuth();
  const { identifier } = useParams();
  const location = useLocation();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const userName = user?.displayName || user?.email || "Gebruiker";
  const title = exams[identifier].title;

  const {
    questions,
    answers,
    isSubmitted,
    reviewResults,
    isReviewing,
    loading,
    handleAnswerChange,
    handleSubmit,
    initExam,
    fetchExamQuestions,
    setIsReviewing,
  } = useExam(user, identifier, location.state?.examResult);

  useEffect(() => {
    if (isAuthenticated) {
      initExam();
      fetchExamQuestions();
    }
  }, [isAuthenticated, initExam, fetchExamQuestions]);

  return (
      <div className="flex flex-col h-full max-w-6xl mx-auto overflow-hidden">
        <h1 className="text-lg font-bold text-center">{title}</h1>
        <div className="flex-1 overflow-y-auto pb-24">
          {loading ? (
            <div className="flex justify-center items-center h-full">
              <div className="loader">{typeof loading === 'string' ? loading : 'Bezig met nakijken...'}</div>
            </div>
          ) : (
            questions.length > 0 && (
              isSubmitted && isReviewing ? ( 
                <div className="flex justify-center">
                  <div className="w-full max-w-4xl">
                    <ReviewTable reviewResults={reviewResults} questions={questions} setCurrentQuestionIndex={setCurrentQuestionIndex} setIsReviewing={setIsReviewing} identifier={identifier} />
                  </div>
                </div>
              ) : (
                <div className="flex w-full">
                  <div className={`${isSubmitted ? 'lg:w-5/6 w-full max-w-3xl mx-auto' : 'w-full mx-auto'}`}>
                    <QuestionRenderer
                      question={questions[currentQuestionIndex]}
                      index={currentQuestionIndex}
                      handleAnswerChange={handleAnswerChange}
                      answers={answers}
                      isSubmitted={isSubmitted}
                    />
                  </div>
                  {isSubmitted && (
                    <div className="lg:w-1/3">
                      <ReviewChat review={reviewResults?.answers[currentQuestionIndex]?.review} userName={userName} />
                    </div>
                  )}
                </div>
              )
            )
          )}
        </div>
        <div className="fixed bottom-0 left-0 right-0 bg-white">
          <div className="max-w-6xl mx-auto">
            <NavigationButtons
              examIdentifier={identifier}
              currentQuestionIndex={currentQuestionIndex}
              answers={answers}
              questions={questions}
              isReviewing={isReviewing}
              isSubmitted={isSubmitted}
              handleSubmit={handleSubmit}
              setCurrentQuestionIndex={setCurrentQuestionIndex}
              setIsReviewing={setIsReviewing}
              reviewResults={reviewResults}
            />
          </div>
        </div>
      </div>
    );
  };
  
  export default Exam;