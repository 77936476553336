import React, { useCallback } from 'react';
import { getScoreClass } from '../utils';

const PracticeNavigation = ({
  examIdentifier,
  currentQuestionIndex,
  answers,
  questions,
  handleSubmitQuestion,
  setCurrentQuestionIndex,
  reviewResults,
  originalAnswers,
}) => {
  const isAnswerFilled = (answer) => {
    if (!answer || answer === null) {
      return false;
    }
    if (typeof answer === 'object') {
      return Object.values(answer).every((val) => val !== '');
    }
    return answer !== '';
  }; 



  const isAnswerChanged = (questionId) => {
    const currentAnswer = answers[questionId];
    const originalAnswer = originalAnswers[questionId];
    return JSON.stringify(currentAnswer) !== JSON.stringify(originalAnswer);
  };

  const getSubmitButtonText = (questionId) => {
    if (reviewResults && reviewResults.answers) {
      const review = reviewResults.answers.find(a => a.questionId === questionId)?.review;
      if (review && isAnswerChanged(questionId)) {
        return 'Resubmit';
      }
    }
    return 'Submit Question';
  };

  const getButtonClass = useCallback((index) => {
    const answerKey = `${examIdentifier}_${index + 1}`;
    const isFilled = isAnswerFilled(answers[answerKey]);
    if (reviewResults && reviewResults.answers) {
      const review = reviewResults.answers.find(a => a.questionId === answerKey)?.review;
      if (review) {
        return getScoreClass(review.total_score, review.max_score, currentQuestionIndex === index);
      }
    }

    if (currentQuestionIndex === index) {
      return 'bg-blue-500 text-white';
    } else if (isFilled) {
      return 'bg-blue-200';
    }
    return '';
  }, [answers, reviewResults, currentQuestionIndex, examIdentifier]);

  return (
    <div className="w-full bg-white p-4">
      <div className="flex justify-between items-center max-w-4xl mx-auto">
        <button
          className="bg-blue-500 text-white px-4 py-2 rounded disabled:opacity-50"
          onClick={() => setCurrentQuestionIndex((prev) => Math.max(prev - 1, 0))}
          disabled={currentQuestionIndex === 0}
        >
          Previous
        </button>
        <div className="flex justify-center space-x-2 flex-grow">
          {questions.map((_, index) => (
            currentQuestionIndex === index || (getButtonClass(index) && window.innerWidth >= 640) ? (
              <button
                key={index}
                className={`px-3 py-1 rounded-full ${getButtonClass(index)}`}
                onClick={() => setCurrentQuestionIndex(index)}
              >
                {index + 1}
              </button>
            ) : null
          ))}
        </div>
        <div className="flex space-x-2">
          <button
            className="bg-blue-500 text-white px-4 py-2 rounded disabled:opacity-50"
            onClick={() => setCurrentQuestionIndex((prev) => Math.min(prev + 1, questions.length - 1))}
            disabled={currentQuestionIndex === questions.length - 1}
          >
            Next
          </button>
          <button
            className="bg-green-500 text-white px-4 py-2 rounded disabled:opacity-50"
            onClick={() => handleSubmitQuestion(questions[currentQuestionIndex].identifier, getSubmitButtonText(questions[currentQuestionIndex].identifier) === 'Resubmit')}
            disabled={!isAnswerChanged(questions[currentQuestionIndex].identifier) && getSubmitButtonText(questions[currentQuestionIndex].identifier) === 'Resubmit'}
          >
            {getSubmitButtonText(questions[currentQuestionIndex].identifier)}
          </button>
        </div>
      </div>
    </div>
  );
};

export default PracticeNavigation;