import React, { useState } from "react";
import { Link } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import AuthPopup from './AuthPopup';

export const Navbar = () => {
  const { user, isAuthenticated, handleLogout, userPlan } = useAuth();
  const [isAuthOpen, setIsAuthOpen] = useState(false);
  const [authMode, setAuthMode] = useState('login');

  const openAuth = (mode) => {
    setAuthMode(mode);
    setIsAuthOpen(true);
  };
  const credits = userPlan?.planNumberExams || 0
  const creditsText = credits > 10 ? " (unlimited)" : ` (credits: ${credits})`

  return (
    <div className="flex items-center justify-between px-4 pt-4 whitespace-nowrap z-50">
      <div className="flex items-center space-x-2">
        <Link to={isAuthenticated ? "/exams" : "/"} className="bg-blue-500 text-white py-2 px-4 rounded">
          {isAuthenticated ? "Examens" : "Home"}
        </Link>
        {isAuthenticated && (
          <Link to="/practice" className="bg-yellow-500 text-white py-2 px-4 rounded">
            Oefenen
          </Link>
        )}
      </div>
      <div className="absolute left-1/2 transform -translate-x-1/2">
        <Link to="/">
          <img src="/logo.png" alt="DutchExams.nl" className="h-14" />
        </Link>
      </div>
      <div className="flex items-center">
        {!isAuthenticated ? (
          <>
            <button onClick={() => openAuth('login')} className="bg-blue-500 text-white py-2 px-4 rounded mr-2">
              Log In
            </button>
            <button onClick={() => openAuth('signup')} className="bg-green-500 text-white py-2 px-4 rounded">
              Sign Up
            </button>
          </>
        ) : (
          <div className="flex items-center">
            <span className="mr-4 hidden sm:inline">
              {user?.displayName || 'User'}{creditsText}
            </span>
            <Link to="/results" className="mr-4 bg-green-500 text-white py-2 px-4 rounded">
              Resultaten
            </Link>
            <button onClick={handleLogout} className="bg-red-500 text-white py-2 px-4 rounded">
              Log Uit
            </button>
          </div>
        )}
      </div>
      <AuthPopup isOpen={isAuthOpen} onClose={() => setIsAuthOpen(false)} initialMode={authMode} />
    </div>
  );
};