import React from 'react';

const FormTask = ({ title, introduction, formSuffixText, formPrefixText, formFields, onChange, values, imageUrl, readOnly, maxCharCount }) => {
  const handleChange = (event, field) => {
    if (event.target.value.length <= maxCharCount) {
      onChange({ ...values, [field]: event.target.value });
    }
  };

  return (
    <div className="p-6 border-2 border-black shadow-lg ">
      <div className="flex">
        <div className="w-1/2 pr-2">
          <div className="mb-4">
            <p className="text-lg font-bold">{title}</p>
            <p className="italic" dangerouslySetInnerHTML={{ __html: introduction }}></p>
            {imageUrl && (
              <div className="mt-4">
                <img alt="" src={imageUrl} className="mt-2" />
              </div>
            )}
            <br /><p><b>Opdracht</b><br />Vul het formulier volledig in. U mag de informatie zelf verzinnen.</p>
          </div>
        </div>
        <div className="w-1/2 pl-2">
          <div className="bg-white p-4 border-2 border-black">
            <p className="font-bold">{title}</p>
            {formPrefixText && <p dangerouslySetInnerHTML={{ __html: formPrefixText }}></p>}
            {formFields.map((field, index) => (
              <div className="mt-4" key={index}>
                <p className="font-bold">
                  {index + 1}. <span dangerouslySetInnerHTML={{ __html: field.label }}></span> {field.requirement && <span className="underline">{field.requirement}</span>}
                </p>
                <textarea
                  className="w-full mt-2 p-2 border border-gray-400 rounded resize-none"
                  rows="3"
                  onChange={(e) => handleChange(e, field.name)}
                  value={values[field.name] || ''}
                  autoCorrect="off"
                  autoCapitalize="off"
                  spellCheck="false"
                  data-gramm="false"
                  readOnly={readOnly}
                ></textarea>
              </div>
            ))}
            <p className="mt-4">
              {formSuffixText}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormTask;