import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import useAuth from '../hooks/useAuth';
import { exams } from '../constants';

const Results = ({ userResults, setUserResults, loading, setLoading }) => {
  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserResults = async () => {
      if (user) {
        console.log("Fetching user results");
        try {
          const token = await user.getIdToken();
          const response = await axios.get('/api/user-results', {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          setUserResults(response.data);
          setLoading(false);
        } catch (error) {
          console.error('Error fetching user results:', error);
          setLoading(false);
        }
      }
    };

    fetchUserResults();
  }, [user, setUserResults, setLoading]);

  const calculateCompletionPercentage = (answers) => {
    const totalQuestions = answers.length;
    const answeredQuestions = answers.filter(answer => answer.answer !== null && answer.answer !== undefined && answer.answer !== "").length;
    return Math.round((answeredQuestions / totalQuestions) * 100);
  };

  const handleResultClick = (result) => {
    console.log('Navigating to exam with result:', result);
    const exam = exams[result.exam_identifier];
    if (exam && exam.isPractice) {
      navigate(`/practice/${result.exam_identifier}`, { state: { examResult: result } });
    } else {
      navigate(`/exam/${result.exam_identifier}`, { state: { examResult: result } });
    }
  };

  const handleDeleteClick = async (submissionId) => {
    if (window.confirm("Weet je zeker dat je dit examen wilt verwijderen?")) {
      setUserResults(userResults.filter(result => result.submissionId !== submissionId));
      try {
        const token = await user.getIdToken();
        await axios.delete(`/api/delete-submission`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            submissionId: submissionId,
          },
        });
      } catch (error) {
        console.error('Error deleting submission:', error);
      }
    }
  };

  const renderResultsTable = (results, title) => (
    <div className="mb-8">
      <h1 className="text-xl font-bold mb-4">{title}</h1>
      <table className="min-w-full bg-white">
        <thead>
          <tr>
            <th className="py-2">Examen</th>
            <th className="py-2">Datum</th>
            <th className="py-2">Score</th>
            <th className="py-2">Acties</th>
          </tr>
        </thead>
        <tbody>
          {results.map((result, index) => (
            <tr
              key={index}
              className={`cursor-pointer hover:bg-gray-100 ${result.submitted && result.totalScore < 27 ? 'bg-red-100' : result.submitted && result.totalScore >= 27 ? 'bg-green-100' : ''}`}
              onClick={() => handleResultClick(result)}
            >
              <td className="border px-4 py-2">{exams[result.exam_identifier].title}</td>
              <td className="border px-4 py-2">{new Date(result.submissionDateTime).toISOString().split('T')[0]}</td>
              <td className="border px-4 py-2">
                {result.submitted ? `${result.total_score}/${result.max_score}` : `Niet ingeleverd (${calculateCompletionPercentage(result.answers)}% voltooid)`}
              </td>
              <td className="border px-4 py-2">
                <button
                  className="bg-red-500 text-white px-2 py-1 rounded"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDeleteClick(result.submissionId);
                  }}
                >
                  Verwijderen
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  if (loading) {
    return <div>Loading results...</div>;
  }

  const examResults = userResults.filter(result => !exams[result.exam_identifier].isPractice);
  const practiceResults = userResults.filter(result => exams[result.exam_identifier].isPractice);

  return (
    <div className="max-w-4xl mx-auto p-4">
      {userResults.length === 0 ? (
        <p>Je hebt nog geen examens of oefeningen gedaan.</p>
      ) : (
        <>
          {examResults.length > 0 && renderResultsTable(examResults, "Examenresultaten")}
          {practiceResults.length > 0 && renderResultsTable(practiceResults, "Oefeningresultaten")}
        </>
      )}
    </div>
  );
};

export default Results;