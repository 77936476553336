import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyAhJhaBwObV17DB3r2WgU9vXWy_54-6l0U",
  authDomain: "b1practice.firebaseapp.com",
  projectId: "b1practice",
  storageBucket: "b1practice.appspot.com",
  messagingSenderId: "725885257849",
  appId: "1:725885257849:web:ef7642cbca09a1b43d2ca8",
  measurementId: "G-KG80SPPH4Z"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { app, auth };
